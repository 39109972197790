<template>
    <div class="wrapper">
        <HeaderNav :auth="$store.state.isAuth"></HeaderNav>
        <div class="container">
            <v-form ref="form" lazy-validation>
                <MailView
                    v-if="!registration && !logining && !recovery"
                    v-bind:value="email"
                    v-bind:validate="validate"
                    v-bind:isLoading="isLoading"
                    @input="changeEmail"
                ></MailView>

                <PassLogin
                    v-if="logining && !recovery"
                    v-bind:value="password"
                    v-bind:login="login"
                    v-bind:password_rules="password_rules"
                    v-bind:recovery="changeRecovery"
                    v-bind:isLoading="isLoading"
                    v-bind:drop="drop"
                    @input="changePass"
                ></PassLogin>

                <PassRegist
                    v-if="registration && !recovery"
                    v-bind:value="password"
                    v-bind:isLoading="isLoading"
                    v-bind:password_rules="password_rules"
                    v-bind:register="register"
                    @input="changePass"
                ></PassRegist>

                <RecoveryPass
                    v-if="recovery"
                    v-bind:value="email"
                    v-bind:validate="sendRecovery"
                    v-bind:isLoading="isLoading"
                    v-bind:drop="drop"
                    v-bind:emailRulesProps="emailRules"
                    v-bind:sendrecoverymail="recovery_send"
                    @input="changeEmail"
                ></RecoveryPass>
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import jwt_decode from "jwt-decode";

import MailView from "@/components/Auth/MailView.vue";
import HeaderNav from "@/components/HeaderNav.vue";
import PassLogin from "@/components/Auth/PassLogin.vue";
import PassRegist from "@/components/Auth/PassRegist.vue";
import RecoveryPass from "@/components/Auth/RecoveryPass.vue";

export default {
    components: { HeaderNav, MailView, PassLogin, PassRegist, RecoveryPass },

    data() {
        return {
            email: "",
            password: "",
            emailRules: [],
            valid_password: false,
            user_found: false,
            user_id: undefined,
            registration: false,
            logining: false,
            password_rules: [],
            recovery: false,
            recovery_send: false,
            isLoading: false,
        };
    },
    methods: {
        drop: function () {
            this.email = "";
            this.password = "";
            this.emailRules = [];
            this.valid = false;
            this.valid_password = false;
            this.user_found = false;
            this.user_id = undefined;
            this.registration = false;
            this.logining = false;
            this.password_rules = [];
            (this.recovery = false), (this.recovery_send = false), (this.isLoading = false);
        },
        sendRecovery() {
            this.$nextTick(async () => {
                try {
                    this.isLoading = true;

                    await axios.post(`${process.env.VUE_APP_MYHOST}/recovery`, {
                        email: this.email,
                    });

                    this.isLoading = false;
                    this.recovery_send = true;
                    this.emailRules = [];
                } catch (error) {
                    console.error(error.message);

                    this.isLoading = false;
                    this.emailRules = ["Неверный почтовый адрес"];
                }
            });
        },
        changeEmail(e) {
            this.email = e.toLowerCase();
            this.emailRules = [];
        },
        changePass(e) {
            this.password = e;
            this.password_rules = [];
        },
        changeRecovery() {
            this.recovery = !this.recovery;
        },
        validate() {
            this.$nextTick(async () => {
                this.valid = true;

                try {
                    this.isLoading = true;

                    const { data } = await axios.get(`${process.env.VUE_APP_MYHOST}/${this.email}`);

                    this.isLoading = false;
                    this.logining = true;
                    this.user_found = true;
                    this.user_id = data.id;
                } catch (error) {
                    console.error(error.message);

                    this.isLoading = false;
                    this.registration = true;
                }
            });
        },
        register: async function () {
            const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

            if (this.password.length < 8) {
                this.password_rules = ["Пароль меньше 8 символов"];
                return;
            }
            if (!pattern.test(this.password)) {
                this.password_rules = ["Пароль не соответствует требованиям"];
                return;
            }

            await this.saveUser();
        },
        async saveUser() {
            try {
                const { data } = await axios.post(`${process.env.VUE_APP_MYHOST}`, {
                    email: this.email,
                    password: this.password,
                });

                this.$store.commit("isAcitvetedUser", false);
                this.$store.commit("changeEmail", this.email);
                this.$store.commit("changeIsAuth", true);
                this.$cookies.set("cng_token", data.jwt);
                this.$router.push({ name: "approveaccount" }).catch(() => {});
            } catch (error) {
                console.log(error.message);
            } finally {
                this.isSending = false;
            }
        },
        login: async function () {
            if (!this.password.length || !this.password) {
                this.password_rules = ["Обязательное поле"];

                return;
            }

            try {
                this.isLoading = true;

                const { data } = await axios.post(`${process.env.VUE_APP_MYHOST}/validate`, {
                    email: this.email,
                    password: this.password,
                });

                if (data.message === "account not active") {
                    this.isLoading = false;
                    this.$store.commit("isAcitvetedUser", false);
                    this.$store.commit("changeEmail", this.email);
                    this.$store.commit("changeIsAuth", true);
                    this.$cookies.set("cng_token", data.jwt);
                    this.$router.push({ name: "LcMain" }).catch(() => {});

                    return;
                }

                this.isLoading = false;
                this.$store.commit("isAcitvetedUser", true);
                this.$store.commit("changeEmail", this.email);
                this.$store.commit("changeIsAuth", true);
                this.$cookies.set("cng_token", data.jwt);
                this.$router.push({ name: "LcMain" }).catch(() => {});
            } catch (error) {
                console.error(error.message);

                this.isLoading = false;
                this.password_rules = ["неверный пароль"];
            }
        },
        redirectApproveEmail() {
            this.$store.state.qrcodes && !this.$store.state.isActivetedUser
                ? this.$router.push({ name: "approve-email" }).catch(() => {})
                : this.$router.push({ name: "LcMain" }).catch(() => {});
        },
        ...mapActions({
            getQrcodes: "getQrcodes",
        }),
    },
    mounted() {
        const token = this.$cookies.get("cng_token");

        if (token && jwt_decode(token).id) this.$router.push({ name: "LcMain" }).catch(() => {});
    },
};
</script>

<style scoped>
@media screen and (max-width: 4000px) and (min-width: 769px) {
    .wrapper {
        padding: 0;

        display: flex;
        justify-content: center;
    }

    .container {
        max-width: 800px;
        width: 100%;
    }
}
</style>
